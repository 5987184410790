import React from 'react';
import ProfileForm from './ProfileForm';
import Header from "../Header";

const Profile = () => {
	return (
		<>
			<Header/>
			<ProfileForm/>
		</>
	);
};

export default Profile;
import React from 'react';
import AuthWrapper from "../Shared/AuthWrapper";
import SignupForm from './SignupForm';

const SignUp = () => {
	return (
		<AuthWrapper>
			<SignupForm/>
		</AuthWrapper>
	)
};

export default SignUp;